import * as React from "react"
import Layout from "../components/layout";

const Profile = (location) => {
    return (
        <Layout location='{location}' title='Home'>
            <div>
                <p>02/03/2019: DDD North 2019 - Lightning Talk: Writing a Book in C# 8 and .Net Core 3</p>
                <p>19/11/2019: <a href="https://www.meetup.com/DotNetNorth/events/266002271/">The Future of Windows Desktop (Using .Net Core 3 to Improve Legacy Apps)</a></p>
                <p>29/03/2020: DDD North 2020 - Lightning Talk: <a href="https://github.com/pcmichaels/adrtalk">What's an ADR anyway</a></p>
                <p>12/06/2020: <a href="https://dotnetcore.show/episode-53-the-desktop-story-with-paul-michaels/">Dot Net Core Podcast - Episode 53 - The Desktop Story</a></p>
                <p>19/10/2020: MiniHack 1 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>23/11/2020: MiniHack 2 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>25/11/2020: <a href="https://www.meetup.com/dotnetsheff/events/274365552/">The Wheels on the Bus</a> at DotNetSheff</p>
                <p>05/12/2020: <a href="https://unhandledexceptionpodcast.com/posts/0006-ddd/">Unhandled Exception Podcast</a></p>
                <p>12/12/2020: <a href="https://www.developerdeveloperdeveloper.com/schedule">The Wheels on the Bus</a> at DDD (online)</p>
                <p>21/12/2020: MiniHack 3 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>19/01/2021: MiniHack 4 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>23/02/2021: MiniHack 5 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>25/02/2021: DotNet Liverpool - <a href="https://www.meetup.com/Dot-NET-Liverpool/events/275543374/">Deep Dive into Azure Service Bus</a></p>
                <p>02/03/2021: DotNetSheff - <a href="https://www.meetup.com/dotnetsheff/events/273184959/">Automatically finding and fixing bugs with Git</a></p>
                <p>23/03/2021: MiniHack 6 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>20/04/2021: MiniHack 7 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>29/04/2021: Leeds Sharp - <a href="https://www.meetup.com/Leeds-Sharp/events/277660804/">Deep Dive into Azure Service Bus</a></p>
                <p>25/05/2021: MiniHack 8 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>22/06/2021: MiniHack 9 - Organiser and founder (see the <a href="https://github.com/pcmichaels/minihack">GitHub repo</a>)</p>
                <p>29/06/2021: DotNetNorth - <a href="https://www.meetup.com/DotNetNorth/events/278859390/">Azure Service Bus</a></p>
                <p>21/07/2021: DotNetSheff - <a href="https://www.meetup.com/dotnetsheff/events/277137057/">An Introduction to Cloudflare Workers</a></p>
                <p>04/08/2021: MiniHack 10 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>14/09/2021: MiniHack 11 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>21/09/2021: Hainton DotNet (<a href="https://www.meetup.com/HaintonsDotNetMeetup/events/279882174/">Design Patterns Using Azure Service Bus</a>)</p>
                <p>12/10/2021: MiniHack 12 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>16/11/2021: MiniHack 13 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>20/12/2021: MiniHack 14 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>24/01/2022: MiniHack 15 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>22/02/2022: MiniHack 16 - Organiser and founder (see the <a href="https://github.com/OnlineMiniHack/minihack">GitHub repo</a> and <a href="https://onlineminihack.github.io/">web site</a>)</p>
                <p>24/02/2022: Manchester Azure & .Net User Group - <a href="https://www.meetup.com/Manchester-Azure-Dot-NET-Meetup/events/283489891/">Discover Azure Service Bus</a></p>
	                
            </div>
        </Layout>
    );
};

export default Profile;
